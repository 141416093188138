$(function() {
    var cardsDataTable,
        dumpsDataTable,
        depositDataTable,
        purchasedCardsDataTable,
        purchasedDumpsDataTable,
        banksDataTable,
        accountsDataTable,
        scamPageDataTable,
        smtpDataTable,
        ftpDataTable,
        whmCpanelDataTable,
        rdpsVpsDataTable,
        documentDataTable,
        letterDataTable,
        scriptDataTable,
        tutorialsDataTable;

    var dumpScript = {
        // General elements
        generalElements: function() {
            // Set default config for jquery validate
            $.validator.setDefaults({
                debug: false,
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorElement: 'div',
                errorPlacement: function(error, element) {
                    error.addClass('invalid-feedback');
                    if ( element.prop( "type" ) === "checkbox" ) {
                        error.insertAfter( element.next( "label" ) );
                    } else {
                        error.insertAfter( element );
                    }
                    //error.insertAfter( element );
                }
            });

            // Initialize select2
            $('.select2').select2({});

            /**
             * Setup CSRF token in ajax request
             */
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            /**
             * This is for delete buttons that are loaded via AJAX in datatables, they will not work right
             * without this block of code
             */
            $(document).ajaxComplete(function(){
                $('.select2').select2({});
                // Update card filter button text
                if ($('#filter-cards').length) {
                    $('#filter-cards').html('Search').removeClass('disabled');
                }
                if ($('#filter-data').length) {
                    $('#filter-data').html('Search').removeClass('disabled');
                }
            });
        },
        // Prepare toast notification
        displayToastNotification: function(type, heading, message) {
            $.toast({
                heading: heading,
                text: message,
                position: 'top-right',
                showHideTransition: 'slide',
                icon: type
            })
        },
        // Validate Register form
        validateRegister: function () {
            $('#register-form').validate({ // initialize the plugin
                rules: {
                    username: {
                        required: true,
                        minlength: 3
                    },
                    password: {
                        required: true,
                        minlength: 6,
                    },
                    password_confirmation: {
                        required: true,
                        minlength: 6,
                        equalTo: '#password',
                    }
                },
                messages: {
                    confirm_password: 'Confirm password should be same as password.',
                },
                submitHandler: function(form) {
                    form.submit();
                }
            });
        },
        // Validate login form
        validateLogin: function () {
            $('#login-form').validate({ // initialize the plugin
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    form.submit();
                }
            });
        },
        // Validate reset password form
        validateForgotPassword: function () {
            $('#forgot-password-form').validate({ // initialize the plugin
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    form.submit();
                }
            });
        },

        // Refresh captcha
        refreshCaptcha: function() {
            $('#refresh-captcha-code').on('click', function() {
                axios.get(refresh_captcha_route)
                    .then(function(response) {
                        if(response.data.status == 'success') {
                            $('#captcha-code > img').attr('src', response.data.captcha)
                        }
                    });
            });
        },
        // Initialize cards dataTable
        initCardDataTable: function () {
            if($('#cards-table').length) {
                cardsDataTable = $('#cards-table').DataTable({
                    pageLength: 25,
                    searching: false,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: card_list_route,
                        type: 'post',
                        data: function(d) {
                            d.category_id   = $('select[name="category_id"] option:selected').val();
                            d.country       = $('select[name="country"] option:selected').val();
                            d.state         = $('select[name="state"] option:selected').val();
                            d.city          = $('select[name="city"] option:selected').val();
                            d.type          = $('select[name="type"] option:selected').val();
                            d.high_balance  = $('select[name="high_balance"] option:selected').val();
                            d.full_info     = $('select[name="full_info"] option:selected').val();
                            d.zip           = $('input[name="zip"]').val();
                            d.bin           = $('input[name="bin"]').val();
                        }
                    },
                    columns: [
                        {data: 'type', name: 'cards.type', className: 'pe-3'},
                        {data: 'bin', name: 'cards.number', className: 'pe-3'},
                        {data: 'expires_at', name: 'cards.expires_at', className: 'pe-3'},
                        // {data: 'high_balance', name: 'cards.high_balance', className: 'pe-3'},
                        // {data: 'full_info', name: 'cards.full_info', className: 'pe-3'},
                        {data: 'option', name: 'option', className: 'pe-3', searchable: false, sortable: false},
                        //{data: 'database', name: 'category.title', className: 'pe-3'},
                        //{data: 'username', name: 'user.username', className: 'pe-3'},
                        {data: 'country', name: 'cards.country', className: 'pe-3'},
                        {data: 'state', name: 'cards.state', className: 'pe-3'},
                        {data: 'city', name: 'cards.city', className: 'pe-3'},
                        {data: 'zip', name: 'cards.zip', className: 'pe-3'},
                        //{data: 'price', name: 'cards.price', className: 'pe-3'},
                        {data: 'bank', name: 'cards.bank', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[9, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });

                // Filter cards
                $('body').on('click', '#filter-cards', function () {
                    $('#filter-cards').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching...').addClass('disabled');
                    cardsDataTable.ajax.reload();
                });

                // Reset card filter
                $('body').on('click', '#reset-card-filters', function (){
                    $('input[name="zip"]').val('');
                    $('input[name="bin"]').val('');
                    $('select[name="category_id"]').val('').trigger('change')
                    $('select[name="country"]').val('').trigger('change')
                    $('select[name="state"]').val('').trigger('change')
                    $('select[name="city"]').val('').trigger('change')
                    $('select[name="type"]').val('').trigger('change')
                    $('select[name="high_balance"]').val('').trigger('change')
                    $('select[name="full_info"]').val('').trigger('change')
                    cardsDataTable.ajax.reload();
                });

                // Get filters
                if ($('#cards-filter-container').length) {
                    axios.get(card_filters_route)
                        .then(function(response) {
                            if(response.data.status == 'success') {
                                $('#cards-filter-container').html(response.data.filters);
                            }
                            $('.select2').select2({});
                            if ($('#filter-cards').length) {
                                $('#filter-cards').html('Search').removeClass('disabled');
                            }
                        });
                }
            }
        },
        // Buy credit card
        buyCreditCard: function () {
            $('body').on('click', '.buy-credit-card', function() {
                var $dis        = $(this);
                var cardId      = $dis.attr('data-id');
                var cardPrice   = $dis.attr('data-price');
                var cardAction  = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + cardPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (cardAction == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(buy_card_route, {card:cardId, price: cardPrice, action: cardAction})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    $.toast({
                                        heading: 'Success',
                                        text: response.data.message,
                                        position: 'top-right',
                                        showHideTransition: 'slide',
                                        icon: 'success'
                                    })
                                    // Reload the data table
                                    cardsDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    $.toast({
                                        heading: 'Error',
                                        text: response.data.message,
                                        position: 'top-right',
                                        showHideTransition: 'slide',
                                        icon: 'error'
                                    })
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },
        // Purchased credit cards
        initPurchasedCardsDataTable: function () {
            if($('#purchased-cards-table').length) {
                purchasedCardsDataTable = $('#purchased-cards-table').DataTable({
                    pageLength: 25,
                    searching: false,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: card_purchased_list_route,
                        type: 'get',
                        data: function(d) {
                            d.category_id   = $('select[name="category_id"] option:selected').val();
                            d.country       = $('select[name="country"] option:selected').val();
                            d.state         = $('select[name="state"] option:selected').val();
                            d.city          = $('select[name="city"] option:selected').val();
                            d.type          = $('select[name="type"] option:selected').val();
                            d.high_balance  = $('select[name="high_balance"] option:selected').val();
                            d.full_info     = $('select[name="full_info"] option:selected').val();
                            d.zip           = $('input[name="zip"]').val();
                            d.bin           = $('input[name="bin"]').val();
                        }
                    },
                    columns: [
                        {data: 'type', name: 'cards.type', className: 'pe-3'},
                        {data: 'bin', name: 'cards.number', className: 'pe-3'},
                        {data: 'expires_at', name: 'cards.expires_at', className: 'pe-3'},
                        // {data: 'high_balance', name: 'cards.high_balance', className: 'pe-3'},
                        // {data: 'full_info', name: 'cards.full_info', className: 'pe-3'},
                        {data: 'option', name: 'option', className: 'pe-3', searchable: false, sortable: false},
                        {data: 'database', name: 'category.title', className: 'pe-3'},
                        {data: 'country', name: 'cards.country', className: 'pe-3'},
                        {data: 'state', name: 'cards.state', className: 'pe-3'},
                        {data: 'city', name: 'cards.city', className: 'pe-3'},
                        {data: 'zip', name: 'cards.zip', className: 'pe-3'},
                        {data: 'price', name: 'cards.price', className: 'pe-3'},
                        {data: 'bank', name: 'cards.bank', className: 'pe-3'},
                        {data: 'sold_at', name: 'cards.sold_at', className: 'pe-3'},
                        {data: 'checker', name: 'checker', class: 'text-center', searchable: false, sortable: false, visible: checkCard}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[11, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    },
                    drawCallback: function (settings) {
                        dumpScript.enableTimerForChecker();
                    }
                });

                // Filter cards
                $('body').on('click', '#filter-cards', function () {
                    $('#filter-cards').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching...').addClass('disabled');
                    purchasedCardsDataTable.ajax.reload();
                });

                // Reset card filter
                $('body').on('click', '#reset-card-filters', function (){
                    $('input[name="zip"]').val('');
                    $('input[name="bin"]').val('');
                    $('select[name="category_id"]').val('').trigger('change')
                    $('select[name="country"]').val('').trigger('change')
                    $('select[name="state"]').val('').trigger('change')
                    $('select[name="city"]').val('').trigger('change')
                    $('select[name="type"]').val('').trigger('change')
                    $('select[name="high_balance"]').val('').trigger('change')
                    $('select[name="full_info"]').val('').trigger('change')
                    purchasedCardsDataTable.ajax.reload();
                });

                // Get filters
                if ($('#cards-filter-container').length) {
                    axios.get(card_filters_route)
                        .then(function(response) {
                            if(response.data.status == 'success') {
                                $('#cards-filter-container').html(response.data.filters);
                            }
                            $('.select2').select2({});
                            if ($('#filter-cards').length) {
                                $('#filter-cards').html('Search').removeClass('disabled');
                            }
                        });
                }

                // Check credit card
                $('body').on('click', '.check-credit-card', function() {
                    var $dis            = $(this);
                    let buttonHtml      = $dis.html();
                    let cardId          = $dis.attr('data-id');
                    let checkerPrice    = $dis.attr('checker-price');
                    Swal.fire({
                        text: 'You will be charged $' + checkerPrice +' and amount will be debited from your account.',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Check',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            $dis.addClass('d-none');
                            $dis.next('.check-button-process').removeClass('d-none');
                            axios.post(check_purchased_route, {item:cardId, price: checkerPrice})
                                .then(function(response) {
                                    if(response.data.status == 'success') {
                                        dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                        // Reload the data table
                                        //purchasedCardsDataTable.ajax.reload();
                                        $dis.next('.check-button-process').html('<i class="bi bi-check-circle"></i> Valid');
                                    }
                                    else {
                                        purchasedCardsDataTable.ajax.reload();
                                        dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                    }
                                    dumpScript.updateBalance(response.data.balance);
                                });
                        }
                    })
                });
            }
        },
        // Initialize dumps dataTable
        initDumpsDataTable: function () {
            if($('#dumps-table').length) {
                dumpsDataTable = $('#dumps-table').DataTable({
                    pageLength: 25,
                    searching: false,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: dumps_list_route,
                        type: 'post',
                        data: function(d) {
                            d.category_id   = $('select[name="category_id"] option:selected').val();
                            d.country       = $('select[name="country"] option:selected').val();
                            d.type          = $('select[name="type"] option:selected').val();
                            d.code          = $('select[name="code"] option:selected').val();
                            d.level         = $('select[name="level"] option:selected').val();
                            d.class         = $('select[name="class"] option:selected').val();
                            d.bank          = $('select[name="bank"] option:selected').val();
                            d.last4         = $('input[name="last4"]').val();
                            d.bin           = $('input[name="bin"]').val();
                            d.tr1           = $('select[name="tr1"] option:selected').val();
                            d.tr2           = $('select[name="tr2"] option:selected').val();
                            d.pin           = $('select[name="pin"] option:selected').val();
                            d.emv           = $('select[name="emv"] option:selected').val();
                            d.ist           = $('select[name="ist"] option:selected').val();
                            d.atr           = $('select[name="atr"] option:selected').val();
                        }
                    },
                    columns: [
                        {data: 'bin', name: 'dumps.number', className: 'pe-3'},
                        {data: 'type', name: 'dumps.type', className: 'pe-3'},
                        {data: 'code', name: 'dumps.code', className: 'pe-3'},
                        {data: 'expires_at', name: 'dumps.expires_at', className: 'pe-3'},
                        {data: 'level', name: 'dumps.level', className: 'pe-3'},
                        {data: 'class', name: 'dumps.class', className: 'pe-3'},
                        {data: 'country', name: 'dumps.country', className: 'pe-3'},
                        // {data: 'tr1', name: 'dumps.tr1', className: 'pe-3'},
                        // {data: 'tr2', name: 'dumps.tr2', className: 'pe-3'},
                        // {data: 'pin', name: 'dumps.pin', className: 'pe-3'},
                        // {data: 'emv', name: 'dumps.emv', className: 'pe-3'},
                        // {data: 'ist', name: 'dumps.ist', className: 'pe-3'},
                        // {data: 'atr', name: 'dumps.atr', className: 'pe-3'},
                        {data: 'option', name: 'option', class: 'text-center', searchable: false, sortable: false},
                        {data: 'bank', name: 'dumps.bank', className: 'pe-3'},
                        {data: 'database', name: 'category.title', className: 'pe-3'},
                        //{data: 'price', name: 'dumps.price', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[10, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });

                // Filter dumps
                $('body').on('click', '#filter-cards', function () {
                    $('#filter-cards').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching...').addClass('disabled');
                    dumpsDataTable.ajax.reload();
                });

                // Reset card filter
                $('body').on('click', '#reset-card-filters', function (){
                    $('input[name="last4"]').val('');
                    $('input[name="bin"]').val('');
                    $('select[name="category_id"]').val('').trigger('change')
                    $('select[name="country"]').val('').trigger('change')
                    $('select[name="type"]').val('').trigger('change')
                    $('select[name="code"]').val('').trigger('change')
                    $('select[name="level"]').val('').trigger('change')
                    $('select[name="class"]').val('').trigger('change')
                    $('select[name="bank"]').val('').trigger('change')
                    $('select[name="tr1"]').val('').trigger('change')
                    $('select[name="tr2"]').val('').trigger('change')
                    $('select[name="pin"]').val('').trigger('change')
                    $('select[name="emv"]').val('').trigger('change')
                    $('select[name="ist"]').val('').trigger('change')
                    $('select[name="atr"]').val('').trigger('change')
                    dumpsDataTable.ajax.reload();
                });

                if ($('#dump-filter-container').length) {
                    axios.get(dumps_filters_route)
                        .then(function(response) {
                            if(response.data.status == 'success') {
                                $('#dump-filter-container').html(response.data.filters);
                            }
                            $('.select2').select2({});
                            if ($('#filter-cards').length) {
                                $('#filter-cards').html('Search').removeClass('disabled');
                            }
                        });
                }
            }
        },

        // Buy dump
        buyDump: function () {
            $('body').on('click', '.buy-dump', function() {
                var $dis        = $(this);
                var dumpId      = $dis.attr('data-id');
                var dumpPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + dumpPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(buy_dump_route, {dump:dumpId, price: dumpPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    $.toast({
                                        heading: 'Success',
                                        text: response.data.message,
                                        position: 'top-right',
                                        showHideTransition: 'slide',
                                        icon: 'success'
                                    })
                                    // Reload the data table
                                    dumpsDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    $.toast({
                                        heading: 'Error',
                                        text: response.data.message,
                                        position: 'top-right',
                                        showHideTransition: 'slide',
                                        icon: 'error'
                                    })
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },
        // Initialize purchased dumps dataTable
        initPurchasedDumpsDataTable: function () {
            if($('#purchased-dumps-table').length) {
                purchasedDumpsDataTable = $('#purchased-dumps-table').DataTable({
                    pageLength: 25,
                    searching: false,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: dumps_purchased_list_route,
                        type: 'get',
                        data: function(d) {
                            d.category_id   = $('select[name="category_id"] option:selected').val();
                            d.country       = $('select[name="country"] option:selected').val();
                            d.type          = $('select[name="type"] option:selected').val();
                            d.code          = $('select[name="code"] option:selected').val();
                            d.level         = $('select[name="level"] option:selected').val();
                            d.class         = $('select[name="class"] option:selected').val();
                            d.bank          = $('select[name="bank"] option:selected').val();
                            d.last4         = $('input[name="last4"]').val();
                            d.bin           = $('input[name="bin"]').val();
                            d.tr1           = $('select[name="tr1"] option:selected').val();
                            d.tr2           = $('select[name="tr2"] option:selected').val();
                            d.pin           = $('select[name="pin"] option:selected').val();
                            d.emv           = $('select[name="emv"] option:selected').val();
                            d.ist           = $('select[name="ist"] option:selected').val();
                            d.atr           = $('select[name="atr"] option:selected').val();
                        }
                    },
                    columns: [
                        {data: 'bin', name: 'dumps.number', className: 'pe-3'},
                        {data: 'type', name: 'dumps.type', className: 'pe-3'},
                        {data: 'code', name: 'dumps.code', className: 'pe-3'},
                        {data: 'expires_at', name: 'dumps.expires_at', className: 'pe-3'},
                        {data: 'level', name: 'dumps.level', className: 'pe-3'},
                        {data: 'class', name: 'dumps.class', className: 'pe-3'},
                        {data: 'country', name: 'dumps.country', className: 'pe-3'},
                        // {data: 'tr1', name: 'dumps.tr1', className: 'pe-3'},
                        // {data: 'tr2', name: 'dumps.tr2', className: 'pe-3'},
                        // {data: 'pin', name: 'dumps.pin', className: 'pe-3'},
                        // {data: 'emv', name: 'dumps.emv', className: 'pe-3'},
                        // {data: 'ist', name: 'dumps.ist', className: 'pe-3'},
                        // {data: 'atr', name: 'dumps.atr', className: 'pe-3'},
                        {data: 'option', name: 'option', class: 'text-center', searchable: false, sortable: false},
                        {data: 'bank', name: 'dumps.bank', className: 'pe-3'},
                        {data: 'database', name: 'category.title', className: 'pe-3'},
                        {data: 'sold_at', name: 'sold_at', className: 'pe-3'},
                        {data: 'checker', name: 'checker', class: 'text-center', searchable: false, sortable: false, visible: checkCard}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[10, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    },
                    drawCallback: function (settings) {
                        dumpScript.enableTimerForChecker();
                    }
                });

                // Filter dumps
                $('body').on('click', '#filter-cards', function () {
                    $('#filter-cards').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching...').addClass('disabled');
                    purchasedDumpsDataTable.ajax.reload();
                });

                // Reset card filter
                $('body').on('click', '#reset-card-filters', function (){
                    $('input[name="last4"]').val('');
                    $('input[name="bin"]').val('');
                    $('select[name="category_id"]').val('').trigger('change')
                    $('select[name="country"]').val('').trigger('change')
                    $('select[name="type"]').val('').trigger('change')
                    $('select[name="code"]').val('').trigger('change')
                    $('select[name="level"]').val('').trigger('change')
                    $('select[name="class"]').val('').trigger('change')
                    $('select[name="bank"]').val('').trigger('change')
                    $('select[name="tr1"]').val('').trigger('change')
                    $('select[name="tr2"]').val('').trigger('change')
                    $('select[name="pin"]').val('').trigger('change')
                    $('select[name="emv"]').val('').trigger('change')
                    $('select[name="ist"]').val('').trigger('change')
                    $('select[name="atr"]').val('').trigger('change')
                    purchasedDumpsDataTable.ajax.reload();
                });

                if ($('#dump-filter-container').length) {
                    axios.get(dumps_filters_route)
                        .then(function(response) {
                            if(response.data.status == 'success') {
                                $('#dump-filter-container').html(response.data.filters);
                            }
                            $('.select2').select2({});
                            if ($('#filter-cards').length) {
                                $('#filter-cards').html('Search').removeClass('disabled');
                            }
                        });
                }

                // Check dumps
                $('body').on('click', '.check-dump', function() {
                    var $dis            = $(this);
                    let cardId          = $dis.attr('data-id');
                    let checkerPrice    = $dis.attr('checker-price');
                    Swal.fire({
                        text: 'You will be charged $' + checkerPrice +' and amount will be debited from your account.',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Check',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            $dis.addClass('d-none');
                            $dis.next('.check-button-process').removeClass('d-none');
                            axios.post(check_purchased_item_route, {item:cardId, price: checkerPrice})
                                .then(function(response) {
                                    if(response.data.status == 'success') {
                                        dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                        // Reload the data table
                                        $dis.next('.check-button-process').html('<i class="bi bi-check-circle"></i> Valid');
                                    }
                                    else {
                                        purchasedDumpsDataTable.ajax.reload();
                                        dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                    }
                                    dumpScript.updateBalance(response.data.balance);
                                });
                        }
                    })
                });
            }
        },
        // Initialize the deposit history datatable
        initDepositHistoryDataTable: function() {
            if($('#deposit-history-table').length) {
                depositDataTable = $('#deposit-history-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: deposit_history_list_route,
                        type: 'get',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'invoice_id', name: 'invoice_id', className: 'pe-3'},
                        {data: 'amount_in_usd', name: 'amount_in_usd', className: 'pe-3'},
                        {data: 'deposit_amount', name: 'deposit_amount', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'status', name: 'status', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[0, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },

        // Enable timer on credit card and dump
        enableTimerForChecker: function () {
            // Check card checker timer
            if($('.cc-check-timer').length) {
                $('.cc-check-timer').each(function (index) {
                    let $dis = $(this);
                    let remainingTime = $dis.html();
                    var interval = setInterval(function() {
                        var timer = remainingTime.split(':');
                        //by parsing integer, I avoid all extra string processing
                        var minutes = parseInt(timer[0], 10);
                        var seconds = parseInt(timer[1], 10);
                        --seconds;
                        minutes = (seconds < 0) ? --minutes : minutes;

                        seconds = (seconds < 0) ? 59 : seconds;
                        seconds = (seconds < 10) ? '0' + seconds : seconds;
                        //minutes = (minutes < 10) ?  minutes : minutes;
                        $dis.html(minutes + ':' + seconds);
                        remainingTime = minutes + ':' + seconds;

                        if (minutes < 0) {
                            clearInterval(interval);
                            $dis.addClass('btn-default disabled')
                                .removeClass('cc-check-timer btn-success')
                                .attr('disabled', true)
                                .html('00:00');
                        }
                    }, 1000);
                });
            }
        },

        // Update balance
        updateBalance: function (balance) {
            $('.available-balance').html(balance);
        },

        // Initialize bank dataTable
        initBankDataTable: function () {
            if($('#banks-table').length) {
                banksDataTable = $('#banks-table').DataTable({
                    pageLength: 25,
                    searching: false,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: bank_list_route,
                        type: 'get',
                        data: function(d) {
                            d.country       = $('select[name="country"] option:selected').val();
                            d.bank          = $('select[name="bank"] option:selected').val();
                        }
                    },
                    columns: [
                        {data: 'bank_name', name: 'banks.bank_name', className: 'pe-3'},
                        {data: 'country', name: 'banks.country', className: 'pe-3'},
                        {data: 'checking', name: 'banks.checking', className: 'pe-3'},
                        {data: 'saving', name: 'banks.saving', className: 'pe-3'},
                        {data: 'security_info', name: 'banks.security_info', className: 'pe-3'},
                        {data: 'description', name: 'banks.description', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[6, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });

                if ($('#filter-container').length) {
                    axios.get(get_filters_route)
                        .then(function(response) {
                            if(response.data.status == 'success') {
                                $('#filter-container').html(response.data.filters);
                            }
                            $('.select2').select2({});
                            if ($('#filter-data').length) {
                                $('#filter-data').html('Search').removeClass('disabled');
                            }
                        });
                }
                // Filter dumps
                $('body').on('click', '#filter-data', function () {
                    $('#filter-data').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching...').addClass('disabled');
                    banksDataTable.ajax.reload();
                });

                // Reset card filter
                $('body').on('click', '#reset-card-filters', function (){
                    $('select[name="country"]').val('').trigger('change')
                    $('select[name="bank"]').val('').trigger('change')
                    banksDataTable.ajax.reload();
                });
            }
        },

        // Buy Banks
        buyBanks: function () {
            $('body').on('click', '.buy-bank', function() {
                var $dis        = $(this);
                var bankId      = $dis.attr('data-id');
                var bankPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + bankPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(buy_bank_route, {bank_id:bankId, price: bankPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    banksDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },

        // Initialized purchased banks dataTable
        initPurchasedBankDataTable: function () {
            if($('#purchased-banks-table').length) {
                $('#purchased-banks-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: card_purchased_list_route,
                        type: 'get',
                        data: function(d) {
                            d.country       = $('select[name="country"] option:selected').val();
                            d.bank          = $('select[name="bank"] option:selected').val();
                        }
                    },
                    columns: [
                        {data: 'bank_name', name: 'banks.bank_name', className: 'pe-3'},
                        {data: 'country', name: 'banks.country', className: 'pe-3'},
                        {data: 'checking', name: 'banks.checking', className: 'pe-3'},
                        {data: 'saving', name: 'banks.saving', className: 'pe-3'},
                        {data: 'security_info', name: 'banks.security_info', className: 'pe-3'},
                        {data: 'description', name: 'banks.description', className: 'pe-3'},
                        {data: 'price', name: 'banks.price', className: 'pe-3'},
                        {data: 'sold_at', name: 'sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[0, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },

        // Initialize account dataTable
        initAccountDataTable: function () {
            if($('#accounts-table').length) {
                accountsDataTable = $('#accounts-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: account_list_route,
                        type: 'POST',
                        data: function(d) {
                            d.country       = $('select[name="country"] option:selected').val();
                            d.type          = $('select[name="type"] option:selected').val();
                            d.info          = $('select[name="info"] option:selected').val();
                            d.logs          = $('select[name="logs"] option:selected').val();
                            d.cookies       = $('select[name="cookies"] option:selected').val();
                            d.mail_access   = $('select[name="mail_access"] option:selected').val();
                        }
                    },
                    columns: [
                        {data: 'country', name: 'accounts.country', className: 'pe-3'},
                        {data: 'type', name: 'accounts.type', className: 'pe-3'},
                        {data: 'email', name: 'accounts.email', className: 'pe-3'},
                        {data: 'ip_address', name: 'accounts.ip_address', className: 'pe-3'},
                        {data: 'username', name: 'accounts.username', className: 'pe-3'},
                        {data: 'password', name: 'accounts.password', className: 'pe-3'},
                        {data: 'options', name: 'options', className: 'pe-3', sortable: false},
                        //{data: 'price', name: 'accounts.price', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[5, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });

                if ($('#filter-container').length) {
                    axios.get(get_filters_route + '?sold=no')
                        .then(function(response) {
                            if(response.data.status == 'success') {
                                $('#filter-container').html(response.data.filters);
                            }
                            $('.select2').select2({});
                            if ($('#filter-cards').length) {
                                $('#filter-cards').html('Search').removeClass('disabled');
                            }
                        });
                }

                // Filter dumps
                $('body').on('click', '#filter-cards', function () {
                    $('#filter-cards').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching...').addClass('disabled');
                    accountsDataTable.ajax.reload();
                });

                // Reset card filter
                $('body').on('click', '#reset-card-filters', function (){
                    $('select[name="country"]').val('').trigger('change')
                    $('select[name="type"]').val('').trigger('change')
                    $('select[name="info"]').val('').trigger('change')
                    $('select[name="logs"]').val('').trigger('change')
                    $('select[name="cookies"]').val('').trigger('change')
                    accountsDataTable.ajax.reload();
                });
            }
        },

        // Buy Banks
        buyAccount: function () {
            $('body').on('click', '.buy-account', function() {
                var $dis            = $(this);
                var accountId       = $dis.attr('data-id');
                var accountPrice    = $dis.attr('data-price');
                var action          = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml      = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + accountPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(buy_account_route, {account_id:accountId, price: accountPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    accountsDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },

        // Initialized purchased banks dataTable
        initPurchasedAccountDataTable: function () {
            if($('#purchased-accounts-table').length) {
                var purchasedAccount = $('#purchased-accounts-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: account_purchased_list_route,
                        type: 'POST',
                        data: function(d) {
                            d.country       = $('select[name="country"] option:selected').val();
                            d.type          = $('select[name="type"] option:selected').val();
                            d.info          = $('select[name="info"] option:selected').val();
                            d.logs          = $('select[name="logs"] option:selected').val();
                            d.cookies       = $('select[name="cookies"] option:selected').val();
                        }
                    },
                    columns: [
                        {data: 'country', name: 'accounts.country', className: 'pe-3'},
                        {data: 'type', name: 'accounts.type', className: 'pe-3'},
                        {data: 'email', name: 'accounts.email', className: 'pe-3'},
                        {data: 'ip_address', name: 'accounts.ip_address', className: 'pe-3'},
                        {data: 'username', name: 'accounts.username', className: 'pe-3'},
                        {data: 'password', name: 'accounts.password', className: 'pe-3'},
                        {data: 'options', name: 'options', className: 'pe-3', sortable: false},
                        {data: 'price', name: 'accounts.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'accounts.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[0, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });

                if ($('#filter-container').length) {
                    axios.get(get_filters_route + '?sold=yes')
                        .then(function(response) {
                            if(response.data.status == 'success') {
                                $('#filter-container').html(response.data.filters);
                            }
                            $('.select2').select2({});
                            if ($('#filter-cards').length) {
                                $('#filter-cards').html('Search').removeClass('disabled');
                            }
                        });
                }

                // Filter dumps
                $('body').on('click', '#filter-cards', function () {
                    $('#filter-cards').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching...').addClass('disabled');
                    purchasedAccount.ajax.reload();
                });

                // Reset card filter
                $('body').on('click', '#reset-card-filters', function (){
                    $('select[name="country"]').val('').trigger('change')
                    $('select[name="type"]').val('').trigger('change')
                    $('select[name="info"]').val('').trigger('change')
                    $('select[name="logs"]').val('').trigger('change')
                    $('select[name="cookies"]').val('').trigger('change')
                    purchasedAccount.ajax.reload();
                });
            }
        },

        // Initialize scam page dataTable
        initScamPageDataTable: function () {
            if($('#scam-pages-table').length) {
                scamPageDataTable = $('#scam-pages-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: scam_page_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'name', name: 'scam_pages.name', className: 'pe-3'},
                        {data: 'screenshot_video_link', name: 'scam_pages.screenshot_video_link', className: 'pe-3'},
                        {data: 'is_checked', name: 'scam_pages.is_checked', className: 'pe-3'},
                        //{data: 'price', name: 'scam_pages.price', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[3, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },

        // Buy scam page
        buyScamPage: function () {
            $('body').on('click', '.buy-scam-page', function() {
                var $dis        = $(this);
                var itemId      = $dis.attr('data-id');
                var itemPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + itemPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(buy_scam_page_route, {item_id:itemId, price: itemPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    scamPageDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },

        // Initialized purchased scam pages dataTable
        initPurchasedScamPageDataTable: function () {
            if($('#purchased-scam-page-table').length) {
                $('#purchased-scam-page-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: scam_page_purchased_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'name', name: 'scam_pages.name', className: 'pe-3'},
                        {data: 'screenshot_video_link', name: 'scam_pages.screenshot_video_link', className: 'pe-3'},
                        {data: 'download_link', name: 'scam_pages.download_link', className: 'pe-3'},
                        {data: 'is_checked', name: 'scam_pages.is_checked', className: 'pe-3'},
                        {data: 'price', name: 'scam_pages.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'scam_pages.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[6, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },
        // Initialize scam page dataTable
        initSmtpDataTable: function () {
            if($('#smtp-table').length) {
                smtpDataTable = $('#smtp-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'country', name: 'smtps.country', className: 'pe-3'},
                        {data: 'type', name: 'smtps.type', className: 'pe-3'},
                        {data: 'host', name: 'smtps.host', className: 'pe-3'},
                        {data: 'detected_host', name: 'smtps.detected_host', className: 'pe-3'},
                        {data: 'connection_type', name: 'smtps.connection_type', className: 'pe-3'},
                        {data: 'is_checked', name: 'smtps.is_checked', className: 'pe-3'},
                        //{data: 'price', name: 'smtps.price', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[5, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },

        // Buy scam page
        buySmtp: function () {
            $('body').on('click', '.buy-smtp', function() {
                var $dis        = $(this);
                var itemId      = $dis.attr('data-id');
                var itemPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + itemPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(item_buy_route, {item_id:itemId, price: itemPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    smtpDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },

        // Initialized purchased scam pages dataTable
        initPurchasedSmtpDataTable: function () {
            if($('#purchased-smtp-table').length) {
                $('#purchased-smtp-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_purchased_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'country', name: 'smtps.country', className: 'pe-3'},
                        {data: 'type', name: 'smtps.type', className: 'pe-3'},
                        {data: 'host', name: 'smtps.host', className: 'pe-3'},
                        {data: 'detected_host', name: 'smtps.detected_host', className: 'pe-3'},
                        {data: 'username', name: 'smtps.username', className: 'pe-3'},
                        {data: 'password', name: 'smtps.password', className: 'pe-3'},
                        {data: 'port', name: 'smtps.port', className: 'pe-3'},
                        {data: 'connection_type', name: 'smtps.connection_type', className: 'pe-3'},
                        //{data: 'connection_limit', name: 'smtps.connection_limit', className: 'pe-3'},
                        {data: 'is_checked', name: 'smtps.is_checked', className: 'pe-3'},
                        {data: 'price', name: 'smtps.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'smtps.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[11, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },

        // Initialize scam page dataTable
        initFtpDataTable: function () {
            if($('#ftp-table').length) {
                ftpDataTable = $('#ftp-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'country', name: 'ftps.country', className: 'pe-3'},
                        {data: 'type', name: 'ftps.type', className: 'pe-3'},
                        {data: 'source', name: 'ftps.source', className: 'pe-3'},
                        {data: 'protocol', name: 'ftps.protocol', className: 'pe-3'},
                        {data: 'host', name: 'ftps.host', className: 'pe-3'},
                        {data: 'tld', name: 'ftps.tld', className: 'pe-3'},
                        {data: 'is_checked', name: 'ftps.is_checked', className: 'pe-3'},
                        //{data: 'price', name: 'ftps.price', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[7, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },

        // Buy scam page
        buyFtp: function () {
            $('body').on('click', '.buy-ftp', function() {
                var $dis        = $(this);
                var itemId      = $dis.attr('data-id');
                var itemPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + itemPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(item_buy_route, {item_id:itemId, price: itemPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    ftpDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },

        // Initialized purchased scam pages dataTable
        initPurchasedFtpDataTable: function () {
            if($('#purchased-ftp-table').length) {
                $('#purchased-ftp-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_purchased_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'country', name: 'ftps.country', className: 'pe-3'},
                        {data: 'type', name: 'ftps.type', className: 'pe-3'},
                        {data: 'source', name: 'ftps.source', className: 'pe-3'},
                        {data: 'protocol', name: 'ftps.protocol', className: 'pe-3'},
                        {data: 'host', name: 'ftps.host', className: 'pe-3'},
                        {data: 'username', name: 'ftps.username', className: 'pe-3'},
                        {data: 'password', name: 'ftps.password', className: 'pe-3'},
                        {data: 'is_checked', name: 'ftps.is_checked', className: 'pe-3'},
                        {data: 'price', name: 'ftps.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'ftps.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[10, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },

        // Initialize scam page dataTable
        initWhmCpanelDataTable: function () {
            if($('#whm-cpanel-table').length) {
                whmCpanelDataTable = $('#whm-cpanel-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'country', name: 'whm_cpanels.country', className: 'pe-3'},
                        {data: 'type', name: 'whm_cpanels.type', className: 'pe-3'},
                        {data: 'source', name: 'whm_cpanels.source', className: 'pe-3'},
                        {data: 'protocol', name: 'whm_cpanels.protocol', className: 'pe-3'},
                        {data: 'host', name: 'whm_cpanels.host', className: 'pe-3'},
                        {data: 'tld', name: 'whm_cpanels.tld', className: 'pe-3'},
                        {data: 'is_checked', name: 'whm_cpanels.is_checked', className: 'pe-3'},
                        //{data: 'price', name: 'whm_cpanels.price', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[7, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },

        // Buy scam page
        buyWhmCpanel: function () {
            $('body').on('click', '.buy-whm-cpanel', function() {
                var $dis        = $(this);
                var itemId      = $dis.attr('data-id');
                var itemPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + itemPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(item_buy_route, {item_id:itemId, price: itemPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    whmCpanelDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },

        // Initialized purchased scam pages dataTable
        initPurchasedWhmCpanelDataTable: function () {
            if($('#purchased-whm-cpanel-table').length) {
                $('#purchased-whm-cpanel-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_purchased_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'country', name: 'whm_cpanels.country', className: 'pe-3'},
                        {data: 'type', name: 'whm_cpanels.type', className: 'pe-3'},
                        {data: 'source', name: 'whm_cpanels.source', className: 'pe-3'},
                        {data: 'protocol', name: 'whm_cpanels.protocol', className: 'pe-3'},
                        {data: 'host', name: 'whm_cpanels.host', className: 'pe-3'},
                        {data: 'username', name: 'whm_cpanels.username', className: 'pe-3'},
                        {data: 'password', name: 'whm_cpanels.password', className: 'pe-3'},
                        {data: 'is_checked', name: 'whm_cpanels.is_checked', className: 'pe-3'},
                        {data: 'price', name: 'whm_cpanels.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'whm_cpanels.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[10, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },
        // Initialize RDPS/VPS dataTable
        initRdpsVpsDataTable: function () {
            if($('#rdps-vps-table').length) {
                rdpsVpsDataTable = $('#rdps-vps-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_list_route,
                        type: 'POST',
                        data: function(d) {
                            d.country           = $('select[name="country"] option:selected').val();
                            d.state             = $('select[name="state"] option:selected').val();
                            d.city              = $('select[name="city"] option:selected').val();
                            d.operating_system  = $('select[name="operating_system"] option:selected').val();
                            d.isp               = $('select[name="isp"] option:selected').val();
                            d.ram               = $('select[name="ram"] option:selected').val();
                            d.zip               = $('select[name="zip"] option:selected').val();
                            d.is_admin          = $('select[name="is_admin"] option:selected').val();
                        }
                    },
                    columns: [
                        {data: 'country', name: 'rdps_vps.country', className: 'pe-3'},
                        {data: 'state', name: 'rdps_vps.state', className: 'pe-3'},
                        {data: 'city', name: 'rdps_vps.city', className: 'pe-3'},
                        {data: 'operating_system', name: 'rdps_vps.operating_system', className: 'pe-3'},
                        {data: 'access', name: 'rdps_vps.access', className: 'pe-3'},
                        {data: 'ram', name: 'rdps_vps.ram', className: 'pe-3'},
                        {data: 'host', name: 'rdps_vps.host', className: 'pe-3'},
                        {data: 'isp', name: 'rdps_vps.isp', className: 'pe-3'},
                        {data: 'zip', name: 'rdps_vps.zip', className: 'pe-3'},
                        {data: 'download_speed', name: 'rdps_vps.download_speed', className: 'pe-3'},
                        {data: 'is_admin', name: 'rdps_vps.is_admin', className: 'pe-3'},
                        {data: 'is_checked', name: 'rdps_vps.is_checked', className: 'pe-3'},
                        //{data: 'price', name: 'rdps_vps.price', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[12, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });

                if ($('#filter-container').length) {
                    axios.get(get_filters_route + '?sold=no')
                        .then(function(response) {
                            if(response.data.status == 'success') {
                                $('#filter-container').html(response.data.filters);
                            }
                            $('.select2').select2({});
                            if ($('#filter-cards').length) {
                                $('#filter-cards').html('Search').removeClass('disabled');
                            }
                        });
                }

                $('body').on('click', '#filter-cards', function () {
                    $('#filter-cards').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching...').addClass('disabled');
                    rdpsVpsDataTable.ajax.reload();
                });

                // Reset card filter
                $('body').on('click', '#reset-card-filters', function (){
                    $('select[name="country"]').val('').trigger('change')
                    $('select[name="state"]').val('').trigger('change')
                    $('select[name="city"]').val('').trigger('change')
                    $('select[name="operating_system"]').val('').trigger('change')
                    $('select[name="isp"]').val('').trigger('change')
                    $('select[name="ram"]').val('').trigger('change')
                    $('select[name="zip"]').val('').trigger('change')
                    $('select[name="is_admin"]').val('').trigger('change')
                    rdpsVpsDataTable.ajax.reload();
                });
            }
        },
        // Buy scam page
        buyRdpsVps: function () {
            $('body').on('click', '.buy-rdps-vps', function() {
                var $dis        = $(this);
                var itemId      = $dis.attr('data-id');
                var itemPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + itemPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(item_buy_route, {item_id:itemId, price: itemPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    rdpsVpsDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },
        // Initialized purchased scam pages dataTable
        initPurchasedRdpsVpsDataTable: function () {
            if($('#purchased-rdps-vps-table').length) {
                var purchasedRdpsVps = $('#purchased-rdps-vps-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_purchased_list_route,
                        type: 'POST',
                        data: function(d) {
                            d.country           = $('select[name="country"] option:selected').val();
                            d.state             = $('select[name="state"] option:selected').val();
                            d.city              = $('select[name="city"] option:selected').val();
                            d.operating_system  = $('select[name="operating_system"] option:selected').val();
                            d.isp               = $('select[name="isp"] option:selected').val();
                            d.ram               = $('select[name="ram"] option:selected').val();
                            d.zip               = $('select[name="zip"] option:selected').val();
                            d.is_admin          = $('select[name="is_admin"] option:selected').val();
                        }
                    },
                    columns: [
                        {data: 'country', name: 'rdps_vps.country', className: 'pe-3'},
                        {data: 'state', name: 'rdps_vps.state', className: 'pe-3'},
                        {data: 'city', name: 'rdps_vps.city', className: 'pe-3'},
                        {data: 'operating_system', name: 'rdps_vps.operating_system', className: 'pe-3'},
                        {data: 'access', name: 'rdps_vps.access', className: 'pe-3'},
                        {data: 'ram', name: 'rdps_vps.ram', className: 'pe-3'},
                        {data: 'host', name: 'rdps_vps.host', className: 'pe-3'},
                        {data: 'isp', name: 'rdps_vps.isp', className: 'pe-3'},
                        {data: 'zip', name: 'rdps_vps.zip', className: 'pe-3'},
                        {data: 'download_speed', name: 'rdps_vps.download_speed', className: 'pe-3'},
                        {data: 'is_admin', name: 'rdps_vps.is_admin', className: 'pe-3'},
                        {data: 'username', name: 'rdps_vps.username', className: 'pe-3'},
                        {data: 'password', name: 'rdps_vps.password', className: 'pe-3'},
                        {data: 'is_checked', name: 'rdps_vps.is_checked', className: 'pe-3'},
                        {data: 'price', name: 'rdps_vps.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'rdps_vps.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[15, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });

                if ($('#filter-container').length) {
                    axios.get(get_filters_route + '?sold=yes')
                        .then(function(response) {
                            if(response.data.status == 'success') {
                                $('#filter-container').html(response.data.filters);
                            }
                            $('.select2').select2({});
                            if ($('#filter-cards').length) {
                                $('#filter-cards').html('Search').removeClass('disabled');
                            }
                        });
                }

                $('body').on('click', '#filter-cards', function () {
                    $('#filter-cards').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching...').addClass('disabled');
                    purchasedRdpsVps.ajax.reload();
                });

                // Reset card filter
                $('body').on('click', '#reset-card-filters', function (){
                    $('select[name="country"]').val('').trigger('change')
                    $('select[name="state"]').val('').trigger('change')
                    $('select[name="city"]').val('').trigger('change')
                    $('select[name="operating_system"]').val('').trigger('change')
                    $('select[name="isp"]').val('').trigger('change')
                    $('select[name="ram"]').val('').trigger('change')
                    $('select[name="zip"]').val('').trigger('change')
                    $('select[name="is_admin"]').val('').trigger('change')
                    purchasedRdpsVps.ajax.reload();
                });
            }
        },
        // Initialize documents dataTable
        initDocumentDataTable: function () {
            if($('#documents-table').length) {
                documentDataTable = $('#documents-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'type', name: 'documents.type', className: 'pe-3'},
                        {data: 'country', name: 'documents.country', className: 'pe-3'},
                        {data: 'state', name: 'documents.state', className: 'pe-3'},
                        {data: 'city', name: 'documents.city', className: 'pe-3'},
                        {data: 'gender', name: 'documents.gender', className: 'pe-3'},
                        {data: 'back_front', name: 'documents.back_front', className: 'pe-3'},
                        {data: 'id_front_back', name: 'documents.id_front_back', className: 'pe-3'},
                        {data: 'selfie', name: 'documents.selfie', className: 'pe-3'},
                        {data: 'selfie_with_id_in_hand', name: 'documents.selfie_with_id_in_hand', className: 'pe-3'},
                        {data: 'person_video_verification', name: 'documents.person_video_verification', className: 'pe-3'},
                        {data: 'person_id_video_verification', name: 'documents.person_id_video_verification', className: 'pe-3'},
                        //{data: 'price', name: 'documents.price', className: 'pe-3'},
                        {data: 'created_at', name: 'created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[8, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },
        // Buy document
        buyDocument: function () {
            $('body').on('click', '.buy-document', function() {
                var $dis        = $(this);
                var itemId      = $dis.attr('data-id');
                var itemPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + itemPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(item_buy_route, {item_id:itemId, price: itemPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    documentDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },
        // Initialized purchased document dataTable
        initPurchasedDocumentDataTable: function () {
            if($('#purchased-documents-table').length) {
                $('#purchased-documents-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_purchased_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'type', name: 'documents.type', className: 'pe-3'},
                        {data: 'country', name: 'documents.country', className: 'pe-3'},
                        {data: 'state', name: 'documents.state', className: 'pe-3'},
                        {data: 'city', name: 'documents.city', className: 'pe-3'},
                        {data: 'gender', name: 'documents.gender', className: 'pe-3'},
                        {data: 'back_front', name: 'documents.back_front', className: 'pe-3'},
                        {data: 'download_link', name: 'documents.download_link', className: 'pe-3'},
                        {data: 'id_front_back', name: 'documents.id_front_back', className: 'pe-3'},
                        {data: 'selfie', name: 'documents.selfie', className: 'pe-3'},
                        {data: 'selfie_with_id_in_hand', name: 'documents.selfie_with_id_in_hand', className: 'pe-3'},
                        {data: 'person_video_verification', name: 'documents.person_video_verification', className: 'pe-3'},
                        {data: 'person_id_video_verification', name: 'documents.person_id_video_verification', className: 'pe-3'},
                        {data: 'price', name: 'documents.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'documents.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[11, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },
        // Initialize documents dataTable
        initLetterDataTable: function () {
            if($('#letters-table').length) {
                letterDataTable = $('#letters-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'type', name: 'letters.type', className: 'pe-3'},
                        {data: 'language', name: 'letters.language', className: 'pe-3'},
                        {data: 'screenshot_link', name: 'letters.screenshot_link', className: 'pe-3'},
                        {data: 'is_checked', name: 'letters.is_checked', className: 'pe-3'},
                        //{data: 'price', name: 'letters.price', className: 'pe-3'},
                        {data: 'created_at', name: 'letters.created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[4, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },
        // Buy document
        buyLetter: function () {
            $('body').on('click', '.buy-letter', function() {
                var $dis        = $(this);
                var itemId      = $dis.attr('data-id');
                var itemPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + itemPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(item_buy_route, {item_id:itemId, price: itemPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    letterDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },
        // Initialized purchased document dataTable
        initPurchasedLetterDataTable: function () {
            if($('#purchased-letters-table').length) {
                $('#purchased-letters-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_purchased_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'type', name: 'letters.type', className: 'pe-3'},
                        {data: 'language', name: 'letters.language', className: 'pe-3'},
                        {data: 'screenshot_link', name: 'letters.screenshot_link', className: 'pe-3'},
                        {data: 'download_link', name: 'letters.download_link', className: 'pe-3'},
                        {data: 'is_checked', name: 'letters.is_checked', className: 'pe-3'},
                        {data: 'price', name: 'letters.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'letters.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'letters.sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[7, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },
        // Initialize scripts dataTable
        initScriptDataTable: function () {
            if($('#scripts-table').length) {
                scriptDataTable = $('#scripts-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'title', name: 'scripts.title', className: 'pe-3'},
                        {data: 'type', name: 'scripts.type', className: 'pe-3'},
                        {data: 'language', name: 'scripts.language', className: 'pe-3'},
                        {data: 'screenshot_link', name: 'scripts.screenshot_link', className: 'pe-3'},
                        {data: 'is_checked', name: 'scripts.is_checked', className: 'pe-3'},
                        //{data: 'price', name: 'scripts.price', className: 'pe-3'},
                        {data: 'created_at', name: 'scripts.created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[5, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },
        // Buy scripts
        buyScript: function () {
            $('body').on('click', '.buy-script', function() {
                var $dis        = $(this);
                var itemId      = $dis.attr('data-id');
                var itemPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + itemPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(item_buy_route, {item_id:itemId, price: itemPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    scriptDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },
        // Initialized purchased scripts dataTable
        initPurchasedScriptDataTable: function () {
            if($('#purchased-scripts-table').length) {
                $('#purchased-scripts-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_purchased_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'title', name: 'scripts.title', className: 'pe-3'},
                        {data: 'type', name: 'scripts.type', className: 'pe-3'},
                        {data: 'language', name: 'scripts.language', className: 'pe-3'},
                        {data: 'screenshot_link', name: 'scripts.screenshot_link', className: 'pe-3'},
                        {data: 'download_link', name: 'scripts.download_link', className: 'pe-3'},
                        {data: 'is_checked', name: 'scripts.is_checked', className: 'pe-3'},
                        {data: 'price', name: 'scripts.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'scripts.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'scripts.sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[7, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },
        // Initialize tutorialsDataTable dataTable
        initTutorialDataTable: function () {
            if($('#tutorials-table').length) {
                tutorialsDataTable = $('#tutorials-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'title', name: 'tutorials.title', className: 'pe-3'},
                        {data: 'description', name: 'tutorials.description', className: 'pe-3'},
                        {data: 'download_link', name: 'tutorials.download_link', className: 'pe-3'},
                        {data: 'is_checked', name: 'tutorials.is_checked', className: 'pe-3'},
                        //{data: 'price', name: 'tutorials.price', className: 'pe-3'},
                        {data: 'created_at', name: 'tutorials.created_at', className: 'pe-3'},
                        {data: 'actions', name: 'actions', class: 'text-center', searchable: false, sortable: false}
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[4, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },
        // Buy scripts
        buyTutorial: function () {
            $('body').on('click', '.buy-tutorial', function() {
                var $dis        = $(this);
                var itemId      = $dis.attr('data-id');
                var itemPrice   = $dis.attr('data-price');
                var action      = $dis.attr('data-action'); // buy or check_buy
                var buttonHtml  = $dis.html();

                Swal.fire({
                    text: 'Once purchase is completed, $' + itemPrice +' is debited from your account.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0d6efd',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: (action == 'check_buy' ? 'Check & Buy' : 'Buy'),
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $dis.html('Processing...');
                        axios.post(item_buy_route, {item_id:itemId, price: itemPrice, action: action})
                            .then(function(response) {
                                if(response.data.status == 'success') {
                                    dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the data table
                                    tutorialsDataTable.ajax.reload();
                                    dumpScript.updateBalance(response.data.balance);
                                }
                                else {
                                    dumpScript.displayToastNotification('error', 'Error', response.data.message);
                                }
                                $dis.html(buttonHtml);
                            });
                    }
                })
            });
        },
        // Initialized purchased scripts dataTable
        initPurchasedTutorialDataTable: function () {
            if($('#purchased-tutorials-table').length) {
                $('#purchased-tutorials-table').DataTable({
                    pageLength: 25,
                    searching: true,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    responsive: true,
                    ajax: {
                        url: item_purchased_list_route,
                        type: 'POST',
                        data: function(d) {

                        }
                    },
                    columns: [
                        {data: 'title', name: 'tutorials.title', className: 'pe-3'},
                        {data: 'description', name: 'tutorials.description', className: 'pe-3'},
                        {data: 'download_link', name: 'tutorials.download_link', className: 'pe-3'},
                        {data: 'is_checked', name: 'tutorials.is_checked', className: 'pe-3'},
                        {data: 'price', name: 'tutorials.price', className: 'pe-3'},
                        {data: 'additional_info', name: 'tutorials.additional_info', className: 'pe-3'},
                        {data: 'sold_at', name: 'tutorials.sold_at', className: 'pe-3'},
                    ],
                    language: {
                        'emptyTable': 'No data available.',
                    },
                    order: [[6, "desc"]],
                    searchDelay: 500,
                    initComplete: function (settings, json) {

                    }
                });
            }
        },

        // Validate general form submission
        validateFormSubmission: function () {
            $('.validate-form').validate({ // initialize the plugin
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    $(form)
                        .find('button[type="submit"]')
                        .addClass('disabled')
                        .attr('disable', true)
                        .html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Checking...');
                    form.submit();
                }
            });
        },
        // Dumps single checker form validate
        dumpsSingleCheckerValidate: function () {
            $('#dumps-single-checker-validate-form').validate({ // initialize the plugin
                rules: {
                    card_number: {
                        required: function () {
                            return $('#track2').val().length <= 0;
                        }
                    },
                    exp_month: {
                        required: function () {
                            return $('#track2').val().length <= 0;
                        }
                    },
                    exp_year: {
                        required: function () {
                            return $('#track2').val().length <= 0;
                        }
                    },
                    cvv: {
                        required: function () {
                            return $('#track2').val().length <= 0;
                        }
                    },
                    track2: {
                        required: function () {
                            return ($('#cardNumber').val().length <= 0 && $('#expMonth').val().length <= 0 && $('#expYear').val().length <= 0 && $('#cvv').val().length <= 0);
                        }
                    }
                },
                messages: {},
                submitHandler: function(form) {
                    var btnHtml     = $(form).find('button[type="submit"]').html();
                    var formAction  = $(form).attr('action');
                    var formData    = $(form).serialize();

                    // Disable submit button
                    dumpScript.disableButton(form);

                    axios.post(formAction, formData)
                        .then(function (response) {
                            if(response.data.status == 'error') {
                                dumpScript.displayToastNotification('error', 'Insufficient Fund', response.data.message);
                            }
                            if(typeof response.data.balance != "undefined") {
                                dumpScript.updateBalance(response.data.balance);
                            }
                            $('#result-container').removeClass('d-none');
                            $('#checker-result').html(response.data.message);
                            dumpScript.scrollToDiv('result-container');
                        })
                        .catch(function (error) {

                        })
                        .finally(function () {
                           dumpScript.enableButton(form, btnHtml);
                        });
                    return false;
                }
            });
        },
        // Dumps bulk checker form validate
        dumpsBulkCheckerValidate: function () {
            $('#dumps-bulk-checker-validate').validate({ // initialize the plugin
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    var btnHtml     = $(form).find('button[type="submit"]').html();
                    var formAction  = $(form).attr('action');
                    var formData    = $(form).serialize();

                    // Disable submit button
                    dumpScript.disableButton(form);

                    axios.post(formAction, formData)
                        .then(function (response) {
                            if(response.data.status == 'error') {
                                dumpScript.displayToastNotification('error', 'Insufficient Fund', response.data.message);
                            }
                            if(typeof response.data.balance != "undefined") {
                                dumpScript.updateBalance(response.data.balance);
                            }
                            $('#result-container').removeClass('d-none');
                            $('#checker-result').html(response.data.message);
                            dumpScript.scrollToDiv('result-container');
                        })
                        .catch(function (error) {

                        })
                        .finally(function () {
                            dumpScript.enableButton(form, btnHtml);
                        });
                    return false;
                }
            });
        },
        // Disable button
        disableButton: function (form) {
            $(form)
                .find('button[type="submit"]')
                .addClass('disabled')
                .attr('disable', true)
                .html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...');
        },

        // Enable button
        enableButton: function (form, btnHtml) {
            $(form)
                .find('button[type="submit"]')
                .removeClass('disabled')
                .attr('disable', false)
                .html(btnHtml);
        },
        // Scroll to div
        scrollToDiv: function (element) {
            $('html, body').animate({
                scrollTop: $("#" + element).offset().top
            }, 2000);
        },

        // Validate checker form
        validateCheckerForm: function () {
            $('form.ajax-validate-form').each(function () {
               $(this).validate({
                   rules: {},
                   messages: {},
                   submitHandler: function(form) {
                       var btnHtml     = $(form).find('button[type="submit"]').html();
                       var formAction  = $(form).attr('action');
                       var formData    = $(form).serialize();

                       // Disable submit button
                       dumpScript.disableButton(form);

                       axios.post(formAction, formData)
                           .then(function (response) {
                               if(response.data.status == 'error') {
                                   dumpScript.displayToastNotification('error', 'Insufficient Fund', response.data.message);
                               }
                               if(typeof response.data.balance != "undefined") {
                                   dumpScript.updateBalance(response.data.balance);
                               }
                               $('#result-container').removeClass('d-none');
                               $('#checker-result').html(response.data.message);
                               dumpScript.scrollToDiv('result-container');
                           })
                           .catch(function (error) {

                           })
                           .finally(function () {
                               dumpScript.enableButton(form, btnHtml);
                           });
                       return false;
                   }
               });
            });
        },
        // Display super offer
        displaySuperOffer: function () {
            if($('.supper-offer-timer').length)
            {
                var actionUrl = $('.supper-offer-timer').attr('data-url');
                let remainingTime = $('.supper-offer-timer').html();
                var interval = setInterval(function() {
                    var timer = remainingTime.split(':');
                    //by parsing integer, I avoid all extra string processing
                    var minutes = parseInt(timer[0], 10);
                    var seconds = parseInt(timer[1], 10);
                    --seconds;
                    minutes = (seconds < 0) ? --minutes : minutes;

                    seconds = (seconds < 0) ? 59 : seconds;
                    seconds = (seconds < 10) ? '0' + seconds : seconds;
                    //minutes = (minutes < 10) ?  minutes : minutes;
                    $('.supper-offer-timer').html(minutes + ':' + seconds);
                    remainingTime = minutes + ':' + seconds;

                    // Check every 5th seconds
                    if(seconds % 5 === 0)
                    {
                        axios.post(actionUrl, {remaining_time: remainingTime})
                            .then(function (response) {

                            })
                            .catch(function () {

                            });
                        // $.ajax({
                        //     type: "POST",
                        //     url: "./metadata.php",
                        //     dataType: 'json',
                        //     data: {action: 'update_meta', key: 'super_offer_time', value: remainingTime},
                        //     beforeSend: function () {
                        //
                        //     },
                        //     success: function (response) {
                        //
                        //     },
                        //     error: function (error) {
                        //
                        //     }
                        // });
                    }
                    if (minutes < 0)
                    {
                        clearInterval(interval);
                        $('.super-offer-container').slideUp('slow');
                        $('.supper-offer-timer').html('00:00');
                    }
                }, 1000)
            }
        },

        // Chat
        chat: function () {
            $('#chat-form').validate({
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    var formAction  = $(form).attr('action');
                    var formData    = $(form).serialize();

                    // First check if user try to submit empty message
                    if($.trim($('#message').val()) === '') {
                        return false;
                    }

                    // Send and save the message
                    axios.post(formAction, formData)
                        .then(function (response) {
                            if(response.data.status == 'success') {
                                // Append message to the message box
                                $('#message-container').append(response.data.message);

                                // Scroll to the last message
                                dumpScript.scrollToLastMessage();
                                // Empty message input
                                $('#message').val('');
                            }
                        })
                        .catch(function (error) {

                        })
                        .finally(function () {
                            //
                        });
                    return false;
                }
            });

            // When press CTRL+Enter then submit the form
            $('#message').keydown(function (e) {
                if (e.ctrlKey && e.keyCode == 13) {
                    $('#chat-form').submit();
                }
            });

            // Scroll to last message on page load
            dumpScript.scrollToLastMessage();
        },

        // Get latest chats
        getLatestChats: function () {
            if($('#message-container').length) {
                var intervalId = window.setInterval(function(){
                    /// call your function here
                    let lastMessageId = $('.message-item:last-child').attr('message-id');
                    axios.post(get_latest_message_route, {last_message: lastMessageId})
                        .then(function (response) {
                            if(response.data.status == 'success') {
                                if(response.data.message !== '') {
                                    $('#message-container').append(response.data.message);

                                    // Scroll to the last message
                                    dumpScript.scrollToLastMessage();
                                }
                            }
                        })
                        .catch(function (error) {

                        });
                }, 5000);
            }
        },

        // Scroll to the last message
        scrollToLastMessage: function () {
            if($('.message-item').length) {
                var items = document.querySelectorAll(".message-item");
                var last = items[items.length-1];
                last.scrollIntoView();
            }
        },

        // Validate profile update
        validateProfiledUpdate: function () {
            $('#profile-update-form').validate({
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    var formAction  = $(form).attr('action');
                    var formData    = $(form).serialize();
                    var btnHtml     = $(form).find('button[type="submit"]').html();

                    // Hide error message
                    $('#profile-validation-error').addClass('d-none');
                    // Disable button
                    dumpScript.disableButton(form);
                    // Prepare form object
                    formData =  new FormData(form);

                    // Send and save the message
                    axios.post(formAction, formData)
                        .then(function (response) {
                            if(response.data.status == 'success') {
                                dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                $(form).trigger("reset");
                            }
                        })
                        .catch(function (error) {
                            let errorMessage = dumpScript.formatErrorMessage(error.response.data);
                            $('#profile-validation-error').html(errorMessage).removeClass('d-none');
                        })
                        .finally(function () {
                            //
                            dumpScript.enableButton(form, btnHtml);
                        });
                    return false;
                }
            });

            // Display image preview
            $('#profile_pic').on('change', function () {
                 const file = this.files[0];
                 if(file) {
                     var reader = new FileReader();
                     reader.onload = function (e) {
                         $('.profile-image-display').attr('src', e.target.result);
                     }
                     reader.readAsDataURL(file);
                 }
            });
        },

        // Validate password update
        validatePasswordUpdate: function () {
            $('#password-update-form').validate({
                rules: {
                    old_password: {
                        required: true,
                        minlength: 6
                    },
                    password: {
                        required: true,
                        minlength: 6,
                    },
                    password_confirmation: {
                        required: true,
                        minlength: 6,
                        equalTo: '#password',
                    }
                },
                messages: {
                    password_confirmation: 'Confirm password should be same as new password.',
                },
                submitHandler: function(form) {
                    var formAction  = $(form).attr('action');
                    var formData    = $(form).serialize();
                    var btnHtml     = $(form).find('button[type="submit"]').html();

                    // Hide error message
                    $('#password-validation-error').addClass('d-none');
                    // Disable button
                    dumpScript.disableButton(form);
                    // Send and save the message
                    axios.post(formAction, formData)
                        .then(function (response) {
                            if(response.data.status == 'success') {
                                dumpScript.displayToastNotification('success', 'Success', response.data.message);
                                $(form).trigger("reset");
                            }
                        })
                        .catch(function (error) {
                            let errorMessage = dumpScript.formatErrorMessage(error.response.data);
                            $('#password-validation-error').html(errorMessage).removeClass('d-none');
                        })
                        .finally(function () {
                            //
                            dumpScript.enableButton(form, btnHtml);
                        });
                    return false;
                }
            });
        },
        // Format error message
        formatErrorMessage: function (errors) {
            if ($.type(errors) === 'object') {
                var message = '';
                $.each(errors, function (index, value) {
                    if($.type(value) === 'string') {
                        message += '<p class="p-0 m-0">'+value+'</p>';
                    }
                    else if($.type(value) === 'array') {
                        $.each(value, function (index, item) {
                            message += '<p class="p-0 m-0">'+item+'</p>';
                        });
                    }
                });
            }
            else {
                message += '<p class="p-0 m-0">'+errors+'</p>';
            }
            return message;
        },
        // Initialize the functions
        init: function () {
            dumpScript.generalElements();
            dumpScript.validateRegister();
            dumpScript.validateLogin();
            dumpScript.validateForgotPassword();
            dumpScript.initCardDataTable();
            dumpScript.buyCreditCard();
            dumpScript.initDumpsDataTable();
            dumpScript.buyDump();
            dumpScript.initDepositHistoryDataTable();
            dumpScript.initPurchasedCardsDataTable();
            dumpScript.initPurchasedDumpsDataTable();
            dumpScript.initBankDataTable();
            dumpScript.buyBanks();
            dumpScript.initPurchasedBankDataTable();
            dumpScript.initAccountDataTable();
            dumpScript.buyAccount();
            dumpScript.initPurchasedAccountDataTable();
            dumpScript.refreshCaptcha();
            dumpScript.initScamPageDataTable();
            dumpScript.buyScamPage();
            dumpScript.initPurchasedScamPageDataTable();
            dumpScript.initSmtpDataTable();
            dumpScript.buySmtp();
            dumpScript.initPurchasedSmtpDataTable();
            dumpScript.initFtpDataTable();
            dumpScript.buyFtp();
            dumpScript.initPurchasedFtpDataTable();
            dumpScript.initWhmCpanelDataTable();
            dumpScript.buyWhmCpanel();
            dumpScript.initPurchasedWhmCpanelDataTable();
            dumpScript.initRdpsVpsDataTable();
            dumpScript.buyRdpsVps();
            dumpScript.initPurchasedRdpsVpsDataTable();
            dumpScript.initDocumentDataTable();
            dumpScript.buyDocument();
            dumpScript.initPurchasedDocumentDataTable();
            dumpScript.initLetterDataTable();
            dumpScript.buyLetter();
            dumpScript.initPurchasedLetterDataTable();
            dumpScript.initScriptDataTable();
            dumpScript.buyScript();
            dumpScript.initPurchasedScriptDataTable();
            dumpScript.initTutorialDataTable();
            dumpScript.buyTutorial();
            dumpScript.initPurchasedTutorialDataTable();
            dumpScript.validateFormSubmission();
            dumpScript.dumpsSingleCheckerValidate();
            dumpScript.dumpsBulkCheckerValidate();
            dumpScript.validateCheckerForm();
            dumpScript.displaySuperOffer();
            dumpScript.chat();
            dumpScript.getLatestChats();
            dumpScript.validateProfiledUpdate();
            dumpScript.validatePasswordUpdate();
        },
    };

    // Initialize the script
    dumpScript.init();
});
